export const columns = [
	{
		title: "商品名称",
		width: "12%",
		dataIndex: "skuName",
	},
	{
		title: "sku编码",
		width: "12%",
		dataIndex: "skuId",
	},
	{
		title: "商品类型",
		width: "7%",
		dataIndex: "mallType",
		customRender: function (mallType) {
			switch (mallType) {
			case 1:
				return 'Jcook'
				break;
			default:
				break;
			}
		}
	},
	{
		title: "店铺名",
		width: "8%",
		dataIndex: "shopName",
	},
	{
		title: "品牌名",
		width: "8%",
		dataIndex: "brandName",
	},
	{
		title: "供应商名",
		width: "8%",
		dataIndex: "vendorName",
	},
	{
		title: "一级分类名称",
		width: "8%",
		dataIndex: "categoryFirstName",
	},
	{
		title: "二级分类名称",
		width: "8%",
		dataIndex: "categorySecondName",
	},
	{
		title: "三级分类名称",
		width: "8%",
		dataIndex: "categoryThirdName",
	},
	{
		title: "主图url",
		width: "8%",
		dataIndex: "mainPhoto",
	},
	{
		title: "售卖价",
		width: "8%",
		dataIndex: "sellPrice",
	},
	{
		title: "折扣价",
		width: "8%",
		dataIndex: "discountPrice",
	},
	{
		title: "浏览量",
		width: "8%",
		dataIndex: "viewsNum",
	}
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 5,
	showTotal: (total) => `共 ${total} 条`,
}