<template>
  <div>
    <a-modal
      width="80%"
      title="选择链接"
      :visible="show"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <a-table
          :columns="columns"
          :data-source="tableData"
          :pagination="pagination"
          :scroll="{ x: 1400 }"
          @change="handleTableChange"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: selectionChoosed,
          }"
          :row-key="
            (record, index) => {
              return record.id;
            }
          "
        >
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { columns, pagination } from "./tableConfig.js";
import { shopPushList } from "@/api/shop/goods";
export default {
	props: {
		show:Boolean
	},
	data() {
		return {
			columns,
			pagination,
			tableData: [],
			selectedRowKeys: [],
			sku:''
		};
	},
	mounted() {
		this.getData()
	},
	methods: {
		async getData(){
			let res = await shopPushList({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
			})
			this.tableData = res.data.rows
			this.pagination.total = res.data.total
			this.selectedRowKeys =[]
		},
		selectionChoosed(data) {
			this.selectedRowKeys = data;
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
		handleCancel(){
			this.$emit('close')
			this.selectedRowKeys = []
		},
		handleOk(){
			if(this.selectedRowKeys.length === 0){
				this.$message.error('请选择')
			}
			else if(this.selectedRowKeys.length > 1){
				this.$message.error('只能选择一条链接')
			}else{
				for(let k of this.tableData){
					if(k.id===this.selectedRowKeys[0]){
						this.sku = k.skuId
					}
				}
				this.$emit('submit',this.selectedRowKeys,this.sku)
				this.selectedRowKeys = []
			}
			
		},
	},
};
</script>

<style>
</style>
