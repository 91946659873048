<template>
  <div>
    <div class="cardTitle">商城轮播图设置</div>
    <a-divider></a-divider>
    <a-button class="add-btn" style="margin: 10px" @click="newSwiper"
      >添加轮播图</a-button
    >
    <a-card
      hoverable
      style="width: 600px; margin-top: 10px"
      v-for="item in cardList"
      :key="item.id"
    >
      <span class="card-close" @click="del(item.id)">×</span>
      <a-row>
        <a-col :span="19">
          appSku编码：{{ item.appSkuId }}
          <div style="color: #00000073; padding-bottom: 10px">
            上传图片尺寸: 750*300
          </div>
          <img
            :src="$ImgUrl(item.imgList[0].url)"
            v-if="item.imgList.length > 0"
            class="list-img"
            alt=""
          />
          <span v-else>暂无图片</span>
        </a-col>
        <a-col :span="4">
          <a-button class="add-btn" @click="edit(item)">修改设置</a-button>
        </a-col>
      </a-row>
    </a-card>
    <!-- list -->
    <a-modal
      :title="add.title"
      :visible="add.show"
      @ok="saveSetting"
      @cancel="newSwiperClose"
      width="600px"
    >
      <a-row>
        <div style="color: #00000073; padding-bottom: 10px">
          上传图片尺寸: 750*300
        </div>
        <commonUpload
          :fileList="fileList"
          @handleChange="handleChange"
        ></commonUpload>
        <div class="new-swiper">
          <a-button
            style="width: 100px; margin-bottom: 10px"
            @click="table.show = true"
            >选择商品</a-button
          >
          appSku编码<a-input
            disabled
            placeholder="请选择商品"
            v-model="form.appSkuId"
            style="width: 90%"
          ></a-input>
        </div>
      </a-row>
    </a-modal>
    <chooseTable :show="table.show" @submit="submit" @close="close" />
  </div>
</template>

<script>
import { form } from "./depend/config";
import {
	swiperList,
	swiperInsert,
	swiperDel,
	swiperUpdate,
} from "@/api/shop/swiper";
import chooseTable from "./depend/table.vue";
export default {
	components: {
		chooseTable,
	},
	data() {
		return {
			inject: ["reload"],
			table: {
				show: false,
			},
			fileList: [],
			cardList: [],
			Listeners:[],
			add: {
				show: false,
				title: "添加轮播图",
			},
			form,
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		async getData() {
			let res = await swiperList();
			this.Listeners = res.data
		},
		// 新增
		newSwiper() {
			this.add = {
				show: true,
				title: "添加轮播图",
			};
		},
		// 编辑
		edit(data) {
			this.add = {
				show: true,
				title: "修改轮播图",
			};
			this.form = data;
			this.form.imgUrls = [];
			if (data.imgList.length > 0) {
				// 如果已经有图片
				this.form.imgUrls.push(data.imgList[0].url);
				const file = [];
				for (let item of data.imgList) {
					let obj = {
						name: item.url.split("_")[0] + "." + item.url.split(".")[1],
						url: this.$ImgUrl(item.url),
						uid: item.url.split("_")[1],
						status: "done",
						thumbUrl: this.$ImgUrl(item.url),
					};
					file.push(obj);
				}
				this.fileList = file;
				// console.log(this.fileList);
			}
		},
		newSwiperClose() {
			this.add = {
				show: false,
				title: "添加轮播图",
			};
			this.fileList = [];
			this.form = {
				id: undefined,
				appSkuId: "",
				imgUrls: [],
			};
		},
		// 选择链接确定
		submit(id, sku) {
			// console.log(id);
			// console.log(sku);
			this.form.appSkuId = sku;
			this.close();
		},
		// 保存
		async saveSetting() {
			if (this.add.title === "添加轮播图") {
				let res = await swiperInsert(this.form);
				if (res.code === 200) {
					this.$message.success(res.msg);
					this.getData();
					this.reload();
					this.newSwiperClose();
				} else {
					this.$message.error(res.msg);
				}
			} else if (this.add.title === "修改轮播图") {
				let res = await swiperUpdate(this.form);
				if (res.code === 200) {
					this.$message.success(res.msg);
					this.getData();
					this.newSwiperClose();
				} else {
					this.$message.error(res.msg);
				}
			}
		},
		// 关闭选择
		close() {
			this.table.show = false;
		},
		// 删除轮播图
		async del(id) {
			this.$confirm({
				title: "是否删除",
				icon: "delete",
				onOk: async () => {
					swiperDel({ rotationId: id }).then((res) => {
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.getData();
						} else {
							this.$message.error(res.msg);
						}
					});
				},
			});
		},
		// 上传图片
		handleChange(data) {
			// console.log(data);
			this.fileList = data;
			// 删除照片
			if (this.fileList.length === 0) {
				this.form.imgUrls = [];
			} else if (data[0].status === "done") {
				this.form.imgUrls.push(data[0].response.data);
			}
		},
	},
	watch:{
		Listeners:{
			handler(val){
				this.cardList = val
			},
			deep: true
		}
	}
};
</script>

<style lang="less" scoped>
/deep/.ant-upload.ant-upload-select-picture-card {
  width: 90%;
  height: 200px;
}
/deep/.ant-upload-list-picture-card .ant-upload-list-item {
  width: 90%;
  height: 200px;
}
/deep/.ant-upload-list-picture-card-container {
  width: 90%;
  height: 200px;
}
.list-img {
  width: 90%;
  height: 200px;
}
.card-close {
  position: absolute;
  right: 10px;
  top: -3px;
  cursor: pointer;
  font-size: 20px;
}
.new-swiper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
