import httpService from "@/request"

// 商品
// 轮播图
export function swiperList(params) {
	return httpService({
		url: `/user/shopRotation/findRotationList`,
		method: 'get',
		params: params,
	})
}
export function swiperDel(params) {
	return httpService({
		url: `/user/shopRotation/delete`,
		method: 'get',
		params: params,
	})
}
export function swiperUpdate(params) {
	return httpService({
		url: `/user/shopRotation/update`,
		method: 'post',
		data: params,
	})
}
export function swiperInsert(params) {
	return httpService({
		url: `/user/shopRotation/insert`,
		method: 'post',
		data: params,
	})
}
